<template>
  <v-container fluid class="mt-8">
        <!-- <v-row>
            <v-col cols="12" class="d-flex justify-end">
                <v-btn dark @click="create">
                    {{$t('merchant_subscription_request.create')}}
                </v-btn>
            </v-col>
        </v-row> -->
        <v-data-table
            mobile-breakpoint=0
            class="elevation-4"
            :loading="loading"
            :headers="i18nHeader"
            sort-by="createdAt"
            :sort-desc="true"
            :items="merchantSubscriptionRequestList"
            :options.sync="options"
            :server-items-length="totalRequests"
            :footer-props="{'items-per-page-options': [10, 20, 30, 40, 50]}"
        >
            <template v-slot:item="{ item, index }">
                <tr>
                    <td>{{ (item.merchantName)? item.merchantName : '-' }} </td>
                    <td>{{ handledCategories(item.category) }} </td>
                    <td>{{ (item.contactName)? item.contactName : '-' }} </td>
                    <td>{{ (item.contactPhone)? item.contactPhone : '-' }} </td>
                    <td>{{ (item.email)? item.email : '-' }} </td>
                    <!-- <td>{{ handledMerchantPlan(item.plan) }} </td> -->
                    <td> {{ $moment(item.createdAt).format('YYYY/MM/DD HH:mm') }}</td>
                    <td>
                        <v-btn small :color="(item.status == 'closed')? 'success': 'error'" @click="changeStatus(item, index)"> <v-icon left>mdi-{{(item.status == 'closed')? 'check': 'close'}}</v-icon> {{showRequestStatus(item.status)}}</v-btn>
                    </td>
                    <td>
                        <v-icon @click="edit(item)">
                            mdi-pencil
                        </v-icon>
                    </td>
                </tr>
                </template>
        </v-data-table>
        <SubscriptionRequestDetailDialog ref="dialog" @refresh="getAllMerchantSubscriptionRequest" :merchantPlanList="merchantPlanList" :categoryList="categoryList" />
        <confirmDialog ref="confirmDialog"/>
  </v-container>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import SubscriptionRequestDetailDialog from "@/views/MerchantSubscriptionRequest/SubscriptionRequestDetailDialog"
import confirmDialog from "@/components/ConfirmDialog"

import api from '@/api'

export default {
    components:{
        SubscriptionRequestDetailDialog,
        confirmDialog
    },
    mounted(){
        // this.getAllMerchantSubscriptionRequest()
        this.getAllCategories()
        this.getAllMerchantPlan()
    },
    computed:{
        ...mapState(['lang']),
        i18nHeader(){
            let result = this.headers

            result.map( item => {
                item.text = this.$t(item.i18n)
            })

            return result
        }
    },
    watch: {
        options: {
            handler () {
                this.getAllMerchantSubscriptionRequest()
            }
        }
    },
    methods: {
        ...mapMutations(['PUSH_GLOBAL_SNACK_MESSAGES']),
        async getAllMerchantSubscriptionRequest() {
            this.loading = true
            try{
                var {sortBy, sortDesc, page, itemsPerPage} = this.options
                let order = (sortDesc[0])? -1 : 1
                let params = {
                    page: page,
                    perPage: itemsPerPage,
                    sort: sortBy,
                    order: order,
                }

                let result = await api.get('/merchantSubscriptionRequest', {params})

                if(result){
                    this.merchantSubscriptionRequestList = result.data.docs
                    this.totalRequests = result.data.totalDocs

                    if (sortBy.length === 1 || sortDesc.length === 1) {
                        this.merchantSubscriptionRequestList = this.merchantSubscriptionRequestList.sort((a, b) => {
                            const sortA = a[sortBy[0]]
                            const sortB = b[sortBy[0]]

                            if (sortDesc[0]) {
                                if (sortA < sortB) return 1
                                if (sortA > sortB) return -1
                                return 0
                            } else {
                                if (sortA < sortB) return -1
                                if (sortA > sortB) return 1
                                return 0
                            }
                        })
                    }
                }
            } catch (error) {
                this.PUSH_GLOBAL_SNACK_MESSAGES({
                    message: error.response.data.message,
                    icon   : 'mdi-close',
                })
                this.loading = false
            }
            this.loading = false
        },
        async getAllCategories() {
            try{
                let result = await api.get('/category')

                if(result){
                    this.categoryList = result.data
                }
            } catch (error) {
                this.PUSH_GLOBAL_SNACK_MESSAGES({
                    message: error.response.data.message,
                    icon   : 'mdi-close',
                })
            }
        },
        async getAllMerchantPlan() {
            try{
                let result = await api.get('/merchantPlan')

                if(result){
                    this.merchantPlanList = result.data
                }
            } catch (error) {
                this.PUSH_GLOBAL_SNACK_MESSAGES({
                    message: error.response.data.message,
                    icon   : 'mdi-close',
                })
            }
        },
        async changeStatus(item, index){
            let action = await this.$refs.confirmDialog.open({
                title  : this.$t('merchant_subscription_request.update_status'),
                content: this.$t('merchant_subscription_request.qa_update_status') + `"${ item.merchantName }"` + `${(item.status == 'open')? this.$t('merchant_subscription_request.qa_update_status_active') : this.$t('merchant_subscription_request.qa_update_status_ban')}?`,
            })

            if (action) {
                let result = false

                item.status = (item.status == 'open')? 'closed' : 'open'

                try {
                    result = await api.patch('/merchantSubscriptionRequest', item)
                } catch (error) {
                    this.PUSH_GLOBAL_SNACK_MESSAGES({
                        message: error.message[0],
                        icon   : 'mdi-close',
                    })
                }

                if (result) {
                    this.merchantSubscriptionRequestList[index].status = item.status
                    this.PUSH_GLOBAL_SNACK_MESSAGES({
                        message: this.$t('global_alert.success_update_status'),
                        icon   : 'mdi-check',
                    })
                }
            }
            this.getAllMerchantSubscriptionRequest()
        },
        create(){
            this.$refs.dialog.open('create', '')
        },
        edit(item){
            this.$refs.dialog.open('edit', item)
        },
        showRequestStatus(status){
            return (status == 'closed')? this.$t('common.accepted'):this.$t('common.applying')
        },
        handledCategories(category){
            let foundCat = category

            if(category && this.categoryList.length > 0){
                foundCat = this.categoryList.find(item => item._id == category)
                if(foundCat) foundCat = foundCat[(this.lang == 'tc')? 'nameZh' : 'nameEng' ]
            }

            return foundCat
        },
        handledMerchantPlan(planID){
            let result = planID

            if(planID && this.merchantPlanList.length > 0){
                let found = this.merchantPlanList.find(item => item._id == planID)
                if(found) result = found[(this.lang == 'tc')? 'nameZh' : 'nameEng' ]
            }

            return result
        }
    },
    data: () => ({
        headers: [
            {i18n: 'common.merchantName',value: 'merchantName'},
            {i18n: 'merchant.categories',value: 'category'}, //id
            {i18n: 'common.contact_name',value: 'contactName'},
            {i18n: 'common.contact_phone',value: 'contactPhone'},
            {i18n: 'common.email',value: 'email'},
            // {i18n: 'merchant.subscription', value: 'plan'}, //id
            {i18n: 'common.created_at', value: 'createdAt'},
            {i18n: 'common.status', value: 'status'},
            {i18n: 'common.edit', value: '', sortable: false },
        ],
        searchByName: '',
        searchByEmail: '',
        merchantSubscriptionRequestList:[],
        categoryList: [],
        merchantPlanList: [],
        loading: false,
        totalRequests:0,
        options: {},
    })
}
</script>
<template>
    <v-container>
        <v-dialog v-model="dialog" persistent max-width="1200">
            <v-toolbar dark>
                <v-btn icon @click="close()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>{{ $t('merchant_subscription_request.'+action) }}</v-toolbar-title>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-container>
                        <v-form
                            ref="form"
                            v-model="formValid"
                            lazy-validation
                        >
                            <v-row>
                                <v-col cols="4">
                                    <v-text-field
                                        v-model="requestDetailObj.merchantName"
                                        :rules="[v => !!v || $t('common.required')]"
                                        :label="$t('common.merchantName')"
                                        :disabled="(action == 'edit')? true : false"
                                    />
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field
                                        v-model="requestDetailObj.contactName"
                                        :rules="[v => !!v || $t('common.required')]"
                                        :label="$t('common.contact_name')"
                                        :disabled="(action == 'edit')? true : false"
                                    />
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field
                                        v-model="requestDetailObj.contactPhone"
                                        :rules="[
                                            v => !!v || $t('common.required'),
                                            v => /^[0-9]{8}$/.test(v) || $t('common.valid_phone')
                                        ]"
                                        :label="$t('common.contact_phone')"
                                        :disabled="(action == 'edit')? true : false"
                                    />
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                        v-model="requestDetailObj.socialMedia"
                                        :label="$t('common.social_media')"
                                        :disabled="(action == 'edit')? true : false"
                                    />
                                </v-col>
                                <v-col cols="6">
                                    <v-select
                                        v-model="requestDetailObj.category"
                                        :items="categoryList"
                                        item-value="_id"
                                        :item-text="(lang == 'tc')? 'nameZh' : 'nameEng'"
                                        :label="$t('merchant.categories')"
                                        :rules="[v => v.length > 0 || $t('common.required')]"
                                        :disabled="(action == 'edit')? true : false"
                                    />
                                </v-col>
                                <!-- <v-col cols="6">
                                    <v-select
                                        v-model="requestDetailObj.plan"
                                        :items="merchantPlanList"
                                        item-value="_id"
                                        :item-text="(lang == 'tc')? 'nameZh' : 'nameEng'"
                                        :label="$t('merchant.subscription')"
                                        :rules="[v => !!v || $t('common.required')]"
                                        :disabled="(action == 'edit')? true : false"
                                    />
                                </v-col> -->
                                <v-col cols="6">
                                    <v-text-field
                                        v-model="requestDetailObj.email"
                                        :label="$t('common.email')"
                                        :rules="[
                                            v => !!v || $t('common.required'),
                                            v => /.+@.+\..+/.test(v) || $t('common.valid_email')
                                        ]"
                                        :disabled="(action == 'edit')? true : false"
                                    />
                                </v-col>
                                <v-col cols="6" v-if="action == 'edit'">
                                    <v-switch
                                        v-model="requestDetailObj.status"
                                        :label="showRequestStatus(requestDetailObj.status)"
                                        true-value="closed"
                                        false-value="open"
                                    />
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea
                                        v-model="requestDetailObj.remarks"
                                        :label="$t('merchant_subscription_request.remarks')"
                                        :rules="[v => !!v || $t('common.required')]"
                                        rows="2"
                                        :disabled="(action == 'edit')? true : false"
                                    />
                                </v-col>
                                <!-- <v-col cols="12">
                                    <v-card>
                                        <v-card-title>{{ $t('merchant.br') }}</v-card-title>
                                        <v-card-text>
                                            <v-img
                                                v-if="requestDetailObj.br && requestDetailObj.br.large && requestDetailObj.br.large.path"
                                                class="mx-auto"
                                                :src="requestDetailObj.br.large.path"
                                                max-width="500"
                                            />
                                            <file-pond
                                                v-else
                                                name="upload"
                                                ref="pond"
                                                :label-idle="$t('img_label')"
                                                accepted-file-types="image/jpeg, image/png"
                                                :files="uploadImageFile"
                                                max-file-size="5MB"
                                                v-on:removefile="requestDetailObj.br = ''"
                                            />
                                        </v-card-text>
                                        <v-card-actions v-if="action != 'edit' && requestDetailObj.br && requestDetailObj.br.large && requestDetailObj.br.large.path">
                                            <v-spacer/>
                                                <v-btn @click="requestDetailObj.br = ''" text>
                                                    <v-icon>mdi-pencil</v-icon>
                                                </v-btn>
                                            <v-spacer/>
                                        </v-card-actions>
                                    </v-card>
                                </v-col> -->
                                <v-col cols="12" v-if="action == 'edit'">
                                    <v-text-field
                                        :value="$moment(requestDetailObj.createdAt).format('YYYY/MM/DD HH:mm')"
                                        :disabled="(action == 'edit')? true : false"
                                        :label="$t('common.created_at')"
                                    />
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                <v-spacer />
                    <v-btn color="blue darken-1" @click="save(action)">{{$t('common.save')}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import api from '@/api'

export default {
    components:{
        // FilePond
    },
    mounted() {
	},
    computed:{
        ...mapState(['lang'])
    },
    props:['merchantPlanList', 'categoryList'],
    methods:{
        ...mapMutations(['PUSH_GLOBAL_SNACK_MESSAGES']),
        async open(action, item){
            if(action == 'edit'){
                let result = false

                try {
                    result = await api.get('/merchantSubscriptionRequest/'+ item._id)
                } catch (error) {
                    this.PUSH_GLOBAL_SNACK_MESSAGES({
                        message: error.response.data.message,
                        icon   : 'mdi-close',
                    })
                }

                if(result){
                    this.requestDetailObj = result.data
                    this.requestDetailObj.category = (result.data.category)? result.data.category._id : ''
                }
            }

            this.action = action
            this.dialog = true
        },
        async save(){
            let validated = this.$refs.form.validate()

            if(validated){
                let result = false
                this.loading = true

                // if(this.requestDetailObj.br == null){
                //     this.PUSH_GLOBAL_SNACK_MESSAGES({
                //         message: this.$t('global_alert.warning_upload_img'),
                //         icon: 'mdi-check',
                //     })

                //     return false
                // }

                try {
                    if(this.action == 'create'){
                        result = await api.post('/merchantSubscriptionRequest', this.requestDetailObj)
                    }else{
                        result = await api.patch('/merchantSubscriptionRequest', this.requestDetailObj)
                    }

                    if(result) {
                        let targetI18n = (this.action === 'edit')? 'global_alert.success_edit' : 'global_alert.success_create'
                        this.resetForm()
                        this.dialog = false
                        this.PUSH_GLOBAL_SNACK_MESSAGES({
                            message: this.$t( targetI18n ),
                            icon   : 'mdi-check',
                        })
                    }
                } catch (error) {
                    this.PUSH_GLOBAL_SNACK_MESSAGES({
                        message: error.response.data.message,
                        icon   : 'mdi-close',
                    })
                }

                this.loading = false
            }
        },
        showRequestStatus(status){
            return (status == 'open')? this.$t('common.accepted'):this.$t('common.applying')
        },
        close(){
            this.resetForm()
            this.dialog = false
        },
        resetForm() {
            this.$emit('refresh')
            this.uploadImageFile = []
            this.requestDetailObj = {
                merchantName: '',
                category: '',
                contactName: '',
                contactPhone: '',
                // plan: '',
                email: '',
                remarks: '',
                resocialMediamarks: '',
                // br: '',
                status: 'closed'
            }
        },
    },
    data: () => ({
        dialog: false,
        uploadImageFile: [],
        requestDetailObj:{
            merchantName: '',
            category: '',
            contactName: '',
            contactPhone: '',
            // plan: '',
            email: '',
            remarks: '',
            socialMedia: '',
            // br: null,
            status: 'closed'
        },
        action: 'edit',
        formValid: true,
        openMenu: false,
        closeMenu: false,

    })
}
</script>